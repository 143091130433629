import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Indent = makeShortcode("Indent");
const Callout = makeShortcode("Callout");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Stability Fee`}</h1>
    <hr></hr>
    <p>{`The Stability Fee is a variable-rate fee continuously added to a Vault owner’s generated Dai balance.`}</p>
    <p>{`Stability Fees are a Risk Parameter designed to address the inherent risk in generating Dai against collateral in Maker Vaults.`}</p>
    <p>{`A portion of the Stability Fee is diverted toward sustaining the operation of the Maker Protocol, which includes the DSR, Risk Teams, and other costs associated with maintaining the protocol.`}</p>
    <p>{`Technical documentation on Stability Fees can be found in the `}<a parentName="p" {...{
        "href": "https://docs.makerdao.com/smart-contract-modules/rates-module/jug-detailed-documentation"
      }}>{`Jug - Detailed Documentation`}</a>{` subsection of the `}<a parentName="p" {...{
        "href": "https://docs.makerdao.com/"
      }}>{`Documentation Portal`}</a>{`.`}</p>
    <h2>{`Stability Fee Rates`}</h2>
    <Indent pad={2} mdxType="Indent">
      <p>{`The Stability Fee for each Vault type changes as a result of the decisions of MKR token holders who govern the protocol.`}</p>
      <p>{`These decisions are based on the recommendation of Risk Teams who perform risk assessments on Collateral used in the system.`}</p>
      <p>{`The Risk Teams may update their proposed Stability Fee when something fundamental changes about the underlying asset or the system as a whole.`}</p>
    </Indent>
    <h2>{`Where Stability Fees Go`}</h2>
    <Indent pad={2} mdxType="Indent">
      <p>{`Stability Fees are collected inside the Maker Protocol’s `}<a parentName="p" {...{
          "href": "https://docs.makerdao.com/smart-contract-modules/core-module/vat-detailed-documentation"
        }}>{`internal balance sheet`}</a>{`.`}</p>
      <p>{`Once the maximum level of surplus is reached, the system automatically sends Dai to a `}<a parentName="p" {...{
          "href": "https://docs.makerdao.com/auctions/the-auctions-of-the-maker-protocol#surplus-auction"
        }}>{`Surplus Auction`}</a>{`. During this auction, Keepers bid in MKR for DAI. Dai is released to the winning bidder while the MKR paid by the winner gets burned.`}</p>
    </Indent>
    <h2>{`Calculating Stability Fees`}</h2>
    <Indent pad={2} mdxType="Indent">
      <p>{`The Stability Fee is continuously compounding interest at a growth rate of x% per second.`}</p>
      <p>{`Technical documentation about how Rates work in the Maker Protocol can be found in the `}<a parentName="p" {...{
          "href": "https://docs.makerdao.com/smart-contract-modules/rates-module"
        }}>{`Rates Module`}</a>{` section of our Documentation Portal`}</p>
    </Indent>
    <Callout icon="lightbulb" mdxType="Callout">
      <h4>{`Example`}</h4>
      <p>{`If the Stability Fee is set to 2%, it will accumulate at 1.0000000006279371924910298109948‬% per second. At the end of year one, the user will owe exactly 2% on the principal.`}</p>
      <p>{`Assuming the user put in 100 Dai, at the end of year one they would have 102.00. At the end of year two, they would have 104.04.`}</p>
    </Callout>
    <h2>{`Stability Fees During Emergency Shutdown`}</h2>
    <Indent pad={2} mdxType="Indent">
      <p>{`During Emergency Shutdown, Vault owners indirectly pay Stability Fees by not being able to withdraw more collateral than the excess in their Vault.`}</p>
    </Indent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      